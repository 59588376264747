//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
export default {
  props: ["pageName", "isLogin"],
  data(){
    return {
      inMail: "", // 未读站内信数量
    }
  },
  computed:{
    menuDataRes () {
      console.log(13245)
      console.log(this.$store.state.user)
      let data = this.tools.menuData.filter( item => item.index !== '9');
      if(this.$store.state.user){
        if(this.$store.state.user.uc_memberType === 1) {
          data = this.tools.menuData.filter( item => item.index !== '9' && item.index !== '12');
        } else if(this.$store.state.user.uc_memberType === 2)  {
          //用户是否在三天前注册
          let isMoreDay = moment.now() > ((this.$store.state.user.uc_registerTime+3600*24*3)*1000);
          isMoreDay && (data = this.tools.menuData.filter( item => item.index !== '9' && item.index !== '11'));
        } else if(this.$store.state.user.uc_memberType === 4) {
          data = this.tools.menuData.filter( item => item.index !== '11');
        }
      }
      console.log(data)
      return data
    },
    activeMenu(){
      return this.$route.path
    }

  },
  watch:{
    isLogin(newVal){
      if(newVal) this.getNoticeNumber()
    }
  },
  async mounted() {

    this.getNoticeNumber()
  },
  methods: {
    goRoute(vc){
      if(vc.index === '/trademarkSearch'){ // 存cookie，显示初始查询页面
        this.$cookies.set("showInto", true)
      }
      if(vc.index === '/trademarkSearchInternational'){ // 存cookie，显示初始查询页面
        this.$cookies.set("showInterInto", true)
      }
      this.$router.push(vc.index)
    },
    getNoticeNumber(){
      if(!this.isLogin) return
      this.$axios.get("/api/Member/getNoticeNumber", {
        params: { id: Math.random() }
      }).then(res => {
        let _data = res.data.data;
        if (_data) {
          this.inMail = _data.inMail;
        }
      })
    }
  }

};
